<template>

  <div>
    <topslogan/>
    <div class="top_head">
      <topnav :category_id="$route.query.choose" :mask=false></topnav>
    </div>
    <div class="category_list">
        <div>
          <h1>成为网站会员</h1>
          <div>支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明支付说明</div>
        </div>
        <div class="reguser">
          <a-button html-type="submit"  style="margin-right: 20px;width:300px" type="primary" @click="getvip">
            成为网站vip
          </a-button>
        </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>

import Config from "@/config";
import topnav from "@/views/topnav";
import topslogan from "@/views/topslogan";
import bottom from "@/views/bottom";
import Storage from "@/common/storage";

export default {
  components: {topslogan, topnav, bottom},
  name: "category_list",
  props: ['my_id', 'choose'],
  data() {
    return {
      bank_bills: "",
      money: 50,
      server_url: Config.data.server_url,

      loading: false,
      language: localStorage.language,
      current: 1,

      pagination: {
        onChange: page => {
          console.log(page);
        },
        pageSize: 10,
      },
    }
  },
  created() {
  },
  methods: {
    async getvip() {
      console.log("getuserinfo", this.language)
      this.loading = true;
      this.navbar = [];

      let now = Date.now();
      let result = await this.$post("/api/customer/order/buyStudent", {

        // token: Storage.token,
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          bank_bills: this.bank_bills,
          money: this.money,
        },
        t: now,

      });

      if (result.status === true) {
        this.$message.success('支付信息已经提交');
        return;
      }

      this.loading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.top_head {
  max-width: 1920px !important;
  margin-left: auto;
  margin-right: auto;
  height: 112px;
  background-color: #002654;

}

.breadcrumb_bg {
  background: #F2F4F5;
  padding-top: 18px;
  height: 64px;
}

.breadcrumb {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;

}

.title {
  color: #fff;
}

.active {
  color: #fff;
  background: #CE1126;
}
.reguser{
  text-align: center;
  margin-top: 20px;
}
.category_list {
  height: 100%;

  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 80px;
  padding-bottom: 80px;

  .right_container {
    width: 100%;
    background-color: #fff;
    height: 100%;
  }

  .left_container {

    .left {
      width: 170px !important;
      padding: 9px 10px;
      border-radius: 8px;
      margin-right: 38px;
      color: #fff;
      background-color: #002654;
      cursor: pointer;

      /deep/ .ant-list-item {
        border-bottom: 0px solid #e8e8e8 !important;
      }
    }
  }

  .left_info {
    padding-top: 32px;

    padding-left: 40px;
    padding-right: 40px;

    h4 {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 34px;
      margin-top: 0;
      padding-top: 0;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      color: #000000;
      line-height: 28px;
      margin-bottom: 20px;
      min-height: 100px;
      max-height: 100px;

    }
  }

  .btn-red {
    color: #fff;
    background-color: #CE1126 !important;
    border-color: #CE1126 !important;
  }

  .articles_list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 34px;
    background-color: #F2F4F5;
    border-radius: 8px;
  }

  .right_info {
    width: 266px;
  }

  .right_img {
    width: 266px;
    height: 280px;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
</style>